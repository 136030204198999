.logo {
  margin: 0 auto;
}

.logo.lightTheme {
  display: block;
}
.logo.darkTheme {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .logo.lightTheme {
    display: none;
  }
  .logo.darkTheme {
    display: block;
  }
}

.ctaButton {
  max-width: 540px;
  margin: 0 auto;
}
