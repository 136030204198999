.video {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: transparent;
    background-size:cover;

    &.fitContain {
        object-fit: contain;
    }
}

//:global(.fullscreen) .video {
//    object-fit: contain;
//}