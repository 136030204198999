.container {
  height: 1em;
  overflow: hidden;
  line-height: 1em;
  display: inline-block;
}

.allDigits {
  position: relative;
  display: flex;
  flex-direction: column;
}

.animate {
  animation: spinit 0.2s 5;
}
@keyframes spinit {
  0% {
    top: 0;
  }
  50% {
    top: -5em;
  }
  100% {
    top: -9em;
  }
}
