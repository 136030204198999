.translucent {
    --opacity: .35;
}

.workoutTitle {
    padding-inline-end: 140px;
    text-align: start;
}

.largeTextIcon {
    margin-bottom: -2px;
}

.smallTextIcon {
    width: 16px;
    margin-bottom: -5px;
}

.headerButton {
    --opacity: 1;
}
