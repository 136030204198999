body.dark {
  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1e1e1e inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
}

.split-pane-ios.split-pane-visible>.split-pane-side,
.split-pane-md.split-pane-visible>.split-pane-side{
  max-width: 350px;
}

.fullscreen {
  .menu-pane-visible {
    display: none;
  }
}