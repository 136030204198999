.topSection {
    pointer-events: none;
    text-align: center;

    @media (min-width: 768px) {
        position: fixed;
        width: 50%;
    }
}

.photo {
    height: 40vh;
    @media (min-width: 768px) {
        height: 70vh;
    }
}

.footer {
    z-index: 8;
    background: var(--ion-background-color);
    background: linear-gradient(0deg, var(--ion-background-color) 0%, var(--ion-background-color) 75%, rgba(255, 255, 255, 0) 100%)
}
@media (prefers-color-scheme: dark) {
    .footer {
        background: linear-gradient(0deg, var(--ion-background-color) 0%, var(--ion-background-color) 75%, rgba(0, 0, 0, 0) 100%)
    }
}

.ctaButton {
    max-width: 540px;
    margin: 0 auto;
}
