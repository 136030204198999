.slideContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-items: center;
  box-sizing: content-box;

  h1,
  p {
    max-width: 34ch;
    align-self: center;
  }
}

.slideImage {
  flex: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
