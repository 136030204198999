.Container {
  position: relative;
  overflow: hidden;
  counter-reset: wizard;
  list-style: none;
}

.Step {
  position: relative;
  float: left;
  text-align: center;
  color: #082745;
}

.Current ~ .Step {
  color: #082745;
}

.Step:before {
  counter-increment: wizard;
  content: counter(wizard);
  display: block;
  color: #082745;
  background-color: #ec008c;
  border: 2px solid #ec008c;
  text-align: center;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 2em;
  position: relative;
  left: 50%;
  margin-bottom: 1em;
  margin-left: -1em;
  z-index: 1;
}

.Current ~ .Step:before {
  color: #082745;
  border-color: #082745;
  background-color: #ffffff;
}

.Step + .Step:after {
  content: '';
  display: block;
  width: 100%;
  background-color: #ec008c;
  height: 2px;
  position: absolute;
  left: -50%;
  top: 1em;
  z-index: 0;
}

.Current ~ .Step:after {
  background-color: #082745;
}
