.safe-padding {
  padding-top: var(--ion-safe-area-top);
  padding-right: var(--ion-safe-area-right);
  padding-bottom: var(--ion-safe-area-bottom);
  padding-left: var(--ion-safe-area-left);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .safe-padding {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: var(--ion-safe-area-right);
    padding-inline-start: var(--ion-safe-area-right);
    -webkit-padding-end: var(--ion-safe-area-left);
    padding-inline-end: var(--ion-safe-area-left);
  }
}

/* Padding */
.no-padding {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-padding {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}
.no-padding-top {
  --padding-top: 0;
  padding-top: 0;
}
.no-padding-start {
  --padding-start: 0;
  padding-left: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-padding-start {
    padding-left: unset;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
  }
}
.no-padding-end {
  --padding-end: 0;
  padding-right: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-padding-end {
    padding-right: unset;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}
.no-padding-bottom {
  --padding-bottom: 0;
  padding-bottom: 0;
}
.no-padding-vertical {
  --padding-top: 0;
  --padding-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-horizontal {
  --padding-start: 0;
  --padding-end: 0;
  padding-left: 0;
  padding-right: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-padding-horizontal {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}
/* Margin */
.no-margin {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-margin {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
  }
}
.no-margin-top {
  --margin-top: 0;
  margin-top: 0;
}
.no-margin-start {
  --margin-start: 0;
  margin-left: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-margin-start {
    margin-left: unset;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }
}
.no-margin-end {
  --margin-end: 0;
  margin-right: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-margin-end {
    margin-right: unset;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
  }
}
.no-margin-bottom {
  --margin-bottom: 0;
  margin-bottom: 0;
}
.no-margin-vertical {
  --margin-top: 0;
  --margin-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.no-margin-horizontal {
  --margin-start: 0;
  --margin-end: 0;
  margin-left: 0;
  margin-right: 0;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .no-margin-horizontal {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
  }
}

@mixin spacing($size) {
  /* Padding */
  .size-#{$size}-padding {
    --padding-start: var(--size#{$size}, 16px);
    --padding-end: var(--size#{$size}, 16px);
    --padding-top: var(--size#{$size}, 16px);
    --padding-bottom: var(--size#{$size}, 16px);
    padding-left: var(--size#{$size}, 16px);
    padding-right: var(--size#{$size}, 16px);
    padding-top: var(--size#{$size}, 16px);
    padding-bottom: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-padding {
      padding-left: unset;
      padding-right: unset;
      -webkit-padding-start: var(--size#{$size}, 16px);
      padding-inline-start: var(--size#{$size}, 16px);
      -webkit-padding-end: var(--size#{$size}, 16px);
      padding-inline-end: var(--size#{$size}, 16px);
    }
  }

  .size-#{$size}-padding-top {
    --padding-top: var(--size#{$size}, 16px);
    padding-top: var(--size#{$size}, 16px);
  }
  .size-#{$size}-padding-start {
    --padding-start: var(--size#{$size}, 16px);
    padding-left: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-padding-start {
      padding-left: unset;
      -webkit-padding-start: var(--size#{$size}, 16px);
      padding-inline-start: var(--size#{$size}, 16px);
    }
  }

  .size-#{$size}-padding-end {
    --padding-end: var(--size#{$size}, 16px);
    padding-right: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-padding-end {
      padding-right: unset;
      -webkit-padding-end: var(--size#{$size}, 16px);
      padding-inline-end: var(--size#{$size}, 16px);
    }
  }

  .size-#{$size}-padding-bottom {
    --padding-bottom: var(--size#{$size}, 16px);
    padding-bottom: var(--size#{$size}, 16px);
  }
  .size-#{$size}-padding-vertical {
    --padding-top: var(--size#{$size}, 16px);
    --padding-bottom: var(--size#{$size}, 16px);
    padding-top: var(--size#{$size}, 16px);
    padding-bottom: var(--size#{$size}, 16px);
  }
  .size-#{$size}-padding-horizontal {
    --padding-start: var(--size#{$size}, 16px);
    --padding-end: var(--size#{$size}, 16px);
    padding-left: var(--size#{$size}, 16px);
    padding-right: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-padding-horizontal {
      padding-left: unset;
      padding-right: unset;
      -webkit-padding-start: var(--size#{$size}, 16px);
      padding-inline-start: var(--size#{$size}, 16px);
      -webkit-padding-end: var(--size#{$size}, 16px);
      padding-inline-end: var(--size#{$size}, 16px);
    }
  }

  /* Margin */
  .size-#{$size}-margin {
    --margin-start: var(--size#{$size}, 16px);
    --margin-end: var(--size#{$size}, 16px);
    --margin-top: var(--size#{$size}, 16px);
    --margin-bottom: var(--size#{$size}, 16px);
    margin-left: var(--size#{$size}, 16px);
    margin-right: var(--size#{$size}, 16px);
    margin-top: var(--size#{$size}, 16px);
    margin-bottom: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-margin {
      margin-left: unset;
      margin-right: unset;
      -webkit-margin-start: var(--size#{$size}, 16px);
      margin-inline-start: var(--size#{$size}, 16px);
      -webkit-margin-end: var(--size#{$size}, 16px);
      margin-inline-end: var(--size#{$size}, 16px);
    }
  }

  .size-#{$size}-margin-top {
    --margin-top: var(--size#{$size}, 16px);
    margin-top: var(--size#{$size}, 16px);
  }
  .size-#{$size}-margin-start {
    --margin-start: var(--size#{$size}, 16px);
    margin-left: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-margin-start {
      margin-left: unset;
      -webkit-margin-start: var(--size#{$size}, 16px);
      margin-inline-start: var(--size#{$size}, 16px);
    }
  }

  .size-#{$size}-margin-end {
    --margin-end: var(--size#{$size}, 16px);
    margin-right: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-margin-end {
      margin-right: unset;
      -webkit-margin-end: var(--size#{$size}, 16px);
      margin-inline-end: var(--size#{$size}, 16px);
    }
  }

  .size-#{$size}-margin-bottom {
    --margin-bottom: var(--size#{$size}, 16px);
    margin-bottom: var(--size#{$size}, 16px);
  }
  .size-#{$size}-margin-vertical {
    --margin-top: var(--size#{$size}, 16px);
    --margin-bottom: var(--size#{$size}, 16px);
    margin-top: var(--size#{$size}, 16px);
    margin-bottom: var(--size#{$size}, 16px);
  }
  .size-#{$size}-margin-horizontal {
    --margin-start: var(--size#{$size}, 16px);
    --margin-end: var(--size#{$size}, 16px);
    margin-left: var(--size#{$size}, 16px);
    margin-right: var(--size#{$size}, 16px);
  }
  @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .size-#{$size}-margin-horizontal {
      margin-left: unset;
      margin-right: unset;
      -webkit-margin-start: var(--size#{$size}, 16px);
      margin-inline-start: var(--size#{$size}, 16px);
      -webkit-margin-end: var(--size#{$size}, 16px);
      margin-inline-end: var(--size#{$size}, 16px);
    }
  }
}

@for $_ from 1 through 16 {
  @include spacing($_);
}
