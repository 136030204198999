.huge {
    font-size: 65px;
}

.extraExtraLarge {
    font-size: 42px;
}

.extraLarge {
    font-size: 32px;
}

.largerAndBold {
    font-size: 24px;
    font-weight: bold;
}

.larger {
    font-size: 24px;
    font-weight: bold;
}

.largeAndBold {
    font-size: 20px;
    font-weight: bold;
}

.large {
    font-size: 20px;
}

.biggerAndBold {
    font-size: 18px;
    font-weight: bold;
}

.bigger {
    font-size: 18px;
}

.body {
    font-size: 16px;
    line-height: 1.5;
}

.smaller {
    font-size: 12px;
}

.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}