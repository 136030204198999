.container {
  width: 100%;
  position: relative; /* If you want text inside of it */

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

:global(.fullscreen) .expandOnFullScreen {
  padding-top: 100vh !important;
}
