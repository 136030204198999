.container {
  max-width: 1200px;
  margin: 0 auto;
}

.logoAndNav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.navWrapper {
  background: #121212;
}

.logo {
  height: 60px;
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.navLink {
  color: #FFF;
  margin-top: var(--size5);
  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: var(--size5);
    margin-right: var(--size5);
  }
}

.divider {
  max-width: 1200px !important;
}

.copyright {
  color: #FFF;
  background: #121212;
}