@import '~swiper/swiper.scss';
@import '~swiper/components/pagination/pagination.scss';
@import '~@ionic/react/css/ionic-swiper.css';

div.swiper-container {
    --bullet-background: var(--slider-bullet-background);
    --bullet-background-active: var(--slider-bullet-background);
    --swiper-pagination-color: var(--slider-bullet-background);
    --swiper-theme-color: var(--slider-bullet-background);
}

.swiper-pagination-bullet {
    background: var(--slider-bullet-background);
    opacity: 1;
    width: 3px;
    height: 3px;
    &:not(.swiper-pagination-bullet-active) {
        transform: translateY(-3px);
    }
}

.swiper-pagination-bullet-active {
    width: 9px;
    height: 9px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 7px;
}