.container {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.fullScreen {
        flex-direction: column;

        .bottomSection {
            visibility: hidden;
        }
    }

    @media (min-width: 576px) {
        flex-direction: row;
    }
}

.topSection {
    flex: 1;
}

.bottomSection {
    flex: 2;
}

.workout {
    .bottomSection {
        overflow: scroll;
    }
}

@media (min-width: 576px) {
    .topSection, .bottomSection {
        overflow: scroll;
    }

    .bottomSection {
        flex: 1;
    }
}

.mediaWrapper {
    position: relative;
    z-index: 9;
    background: var(--ion-background-color);

    &.fullScreen {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}