.cardItem {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  position: relative;
}

.grow .itemImage {
  overflow: hidden;
  & > * {
    transition: all .2s ease-in-out;
  }
}

.grow:hover .itemImage > * {
  transform: scale(1.02);
}

.itemTitle {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: var(--size6);
  background: linear-gradient(0deg, rgba(30, 30, 30, .75) 0%, rgba(255, 255, 255, 0) 100%);
}

.itemTitleText {
  color: #FFF;
}

@media (prefers-color-scheme: dark) {
  .itemTitle {
    background: linear-gradient(0deg, rgba(30, 30, 30, .75) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.author {
  margin-top: auto;
}
