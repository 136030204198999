.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: var(--ion-background-color);
}

.errorImage {
    width: 250px;
    height: 250px;
    transform: rotate(0.05turn);
}