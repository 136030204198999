.container {
  --background: transparent;
  --padding-start: unset;

  &.small {
    .photoContainer {
      width: 50px;
      height: 50px;
    }
  }

  &.large {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: var(--size4);

    .photoContainer {
      width: 100px;
      height: 100px;
      margin-top: var(--size9);
      margin-bottom: var(--size3);
    }
  }
}

.photoContainer {
  background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4);
  box-shadow: inset 0 0 0 1px rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4);
  overflow: hidden;
}

.photo {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.title {
  text-transform: uppercase;
}

.name {
  color: var(--ion-text-color);
}
