.container {
  width: 100%;
  height: 100%;
  object-fit: cover;

  opacity: 0;
  transition: opacity .3s ease-in-out;

  &.fitContain {
    object-fit: contain;
  }

  &.loaded {
    opacity: 1;
  }
}

//:global(.fullscreen) .container {
//  object-fit: contain;
//}
