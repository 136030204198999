.w-100 {
    width: 100%;
}

.min-w-100 {
    min-width: 100%;
}

.h-100 {
    height: 100%;
}

.min-h-100 {
    min-height: 100%;
}

.d-flex {
    display: flex;
}

.direction-column {
    flex-direction: column;
}

.compact {
    width: 768px;
    margin: 0 auto;
}

.flex-1 {
    flex: 1;
}

.line-height-1 {
    line-height: 1;
}

.position-relative {
    position: relative;
}

.max-width {
    max-width: 1200px;
}

.hide-when-empty:empty {
    display: none;
}