.d-inline-flex {
    display: inline-flex;
}

.centered {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centered-vertical {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.centered-horizontal {
    display: flex;
    justify-content: center;
}

.text-align-start {
    text-align: start;
}

.text-align-center {
    text-align: center;
}

.text-align-end {
    text-align: end;
}