.container {
}

.energyContent {
  border-radius: 50px;
  background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  display: flex;
  justify-content: space-evenly;

  .component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: var(--size2);
    padding-bottom: var(--size2);
  }

  .componentValue {
    font-weight: bold;
  }

  .componentLabel {

  }
}

.measurementTypes {
  list-style: none;
  padding: 0;
  margin-top: var(--size1);
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.measurementType {
  padding-left: var(--size3);
  padding-right: var(--size3);
}
