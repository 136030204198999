.container {
    width: 100%;
}

.dropTarget {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 4px dashed transparent;
    }
    &.active::after{
        border-color: var(--ion-color-primary);
    }
}
