.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo.lightTheme {
  display: block;
}
.logo.darkTheme {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .logo.lightTheme {
    display: none;
  }
  .logo.darkTheme {
    display: block;
  }
}

.storeLink {
  display: inline-block;
  margin-left: calc(var(--size5) - var(--padding-start));
  img {
    height: 40px;
  }
}