.page {
  background: #FFF;
  color: #000;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .h100 {
    height: 100%;
    min-height: inherit;
  }
}

.mainSection {
  color: #FFF;
  background: #121212;
  min-height: 100vh;

  .sectionImage {
    object-position: bottom right;
  }

  .largeSectionImage {
    position: absolute;
    bottom: 0;
    height: 300px !important;
    max-height: calc(100vh - 500px) !important;
    display: none;
  }

  @media (min-width: 992px) {
    .sectionImage {
      display: none;
    }

    .largeSectionImage {
      display: block;
    }
  }
}

.sectionImage {
  margin: 0 auto;
  min-height: 213px;
}

.tipsImage {
  max-width: 85%;
}

.trainerImage {
  max-width: 300px;
  @media (max-width: 767px) {
    padding-top: 75% !important;
  }
}

.logo {
  height: 90px;
}

.storeLink {
  display: inline-block;
  img {
    height: 50px;
  }
}

.navLink {
  color: #FFF;
}

.midSection {
  color: #000;
  background: #FFF;
}

.trainer {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    background: var(--ion-color-secondary);
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.bottomSection {
  color: #FFF;
  background: #121212;
}

.sectionText {
  @media (max-width: 767px) {
    padding: 0;
  }
}

.appLinksAndLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .bottomSectionLogo {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .bottomSectionLogo {
      display: block;
    }
  }
}
